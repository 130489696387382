var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c4352d7516af3c2e5076c016a5e289c29f3c416a"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { captureConsoleIntegration } from '@sentry/integrations';
import {
  init,
  replayCanvasIntegration,
  replayIntegration,
} from '@sentry/nextjs';
import { isDefined } from 'helpers/typed/filters';

init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 100%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,

  maxValueLength: 500,
  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        process.env.NEXT_PUBLIC_APOLLO_CORE_URI,
        process.env.NEXT_PUBLIC_APOLLO_ACTIVITY_URI,
        '/api',
      ].filter(isDefined),
    }),
    replayCanvasIntegration(),
    captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
});
